import imgArticleglass1 from '../assets/publications/article-glass-1.webp';
import imgArticleglass2 from '../assets/publications/article-glass-2.webp';
import imgFinancieeldagblad1 from '../assets/publications/financieel-dagblad-1.webp';
import imgFinancieeldagblad2 from '../assets/publications/financieel-dagblad-2.webp';
import imgGlas from '../assets/publications/glas.webp';
import imgObject from '../assets/publications/object.webp';
import imgPzc from '../assets/publications/pzc.webp';
import imgStudio040toenhiereindhoven from '../assets/publications/studio-040-toen-hier-eindhoven.webp';
import imgVtwonenBroadcast from '../assets/publications/vtwonen-broadcast.webp';
import imgVtwonenFront from '../assets/publications/vtwonen-front.webp';
import imgVtwonenTekst from '../assets/publications/vtwonen-tekst.webp';
import imgWebsiteVtwonen from '../assets/publications/website-vtwonen.webp';

export interface PublicationItem {
  name: string;
  image: string;
}

export const publications: PublicationItem[] = [
  { name: 'Financieel dagblad 20/06/2020', image: imgFinancieeldagblad1 },
  { name: 'Financieel dagblad 20/06/2020', image: imgFinancieeldagblad2 },
  { name: 'VT Wonen nr 02 front', image: imgVtwonenFront },
  { name: 'tekst VT Wonen magazine', image: imgVtwonenTekst },
  { name: 'object rotterdam.jpg', image: imgObject },
  { name: 'Glas december 2019', image: imgGlas },
  { name: 'article Glass', image: imgArticleglass1 },
  { name: 'Article Glass', image: imgArticleglass2 },
  { name: 'studio 040 toen&hier Eindhoven', image: imgStudio040toenhiereindhoven },
  { name: 'Broadcast VTwonen march 2019', image: imgVtwonenBroadcast },
  { name: 'website VTwonen work portrait', image: imgWebsiteVtwonen },
  { name: 'PZC', image: imgPzc },
];
