export interface PressDownload {
  name: string;
  url: string;
}

export const pressDownloads: PressDownload[] = [
  { name: 'Aperitif white background.jpg', url: '/static/press-kit/aperitif-the-samples.jpg' },
  { name: 'aperitif the samples.jpg', url: '/static/press-kit/aperitif-white-background.jpg' },
  { name: 'Photographs: Femke Rijerman photo aperitif design academy book.jpg', url: '/static/press-kit/photo-aperitif-design-academy-book.jpg' },
  { name: 'tekst project aperitif.pdf', url: '/static/press-kit/tekst-project-aperitif.pdf' },
];
