import { FC } from 'react';
import ImageGallery, { ReactImageGalleryProps } from 'react-image-gallery';
import styles from './Gallery.module.scss';

interface GalleryProps extends ReactImageGalleryProps {
  className?: string;
}

export const Gallery: FC<GalleryProps> = (props) => (
  <ImageGallery {...props} additionalClass={styles.gallery} />
);
