import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

interface PageMetaProps {
  title: string;
}

export const PageMeta: FC<PageMetaProps> = ({ title }) => {
  const location = useLocation();
  const canonical = `https://www.evaoyevaar.com${location.pathname}`;

  return (
    <Helmet>
      <title>{title}</title>

      <meta property="og:title" content={`${title} | Eva Oyevaar`} />
      <meta property="og:url" content="https://www.evaoyevaar.com" />
      <meta property="og:site_name" content="Eva Oyevaar" />
      <meta property="og:type" content="website" />

      <link rel="canonical" href={canonical} />
    </Helmet>
  );
};
