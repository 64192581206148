import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Gallery } from '../components/gallery/Gallery';
import { PageContent } from '../components/layout/PageContent';
import { PageMeta } from '../components/layout/PageMeta';
import styles from './PostCards.module.scss';

const images = new Array(12).fill(0).map((_, i) => ({
  fullscreen: `/static/img/postcards/${i + 1}.l.webp`,
  original: `/static/img/postcards/${i + 1}.m.webp`,
  thumbnail: `/static/img/postcards/${i + 1}.m.webp`,
}));

export const PostCards: FC = () => (
  <PageContent className={styles.container}>
    <PageMeta title="Postcards for sale" />

    <div className={styles.gallery_container}>
      <div className={styles.description}>
        <p>
          One card for &euro;3.50
          <br />
          Four cards for &euro;10.-
        </p>

        <p>
          <Link to="/contact">Email me</Link>
          {' '}
          to order
          <br />
          Cards come packaged with a black envelope
        </p>
      </div>

      <Gallery items={images} />
    </div>
  </PageContent>
);
