import { FC } from 'react';
import styles from './Footer.module.scss';

export const Footer: FC = () => (
  <footer className={styles.container}>
    <div>All rights reserved to Eva Oyevaar</div>
    <div>
      {` Studio Eva Oyevaar ${new Date().getFullYear()}`}
    </div>
    <div>The alternative stork</div>
  </footer>
);
