import { FC, ReactNode } from 'react';
import { PageContent } from '../layout/PageContent';
import styles from './WorkDetail.module.scss';
import { WorkItemImage } from '../../data/work';
import { Gallery } from '../gallery/Gallery';

interface WorkDetailProps {
  title: string;
  subtitle?: string;
  description: ReactNode;
  year?: number;
  material?: string;
  images?: WorkItemImage[];
}

export const WorkDetail: FC<WorkDetailProps> = ({
  title, subtitle, description, year, material, images,
}) => (
  <PageContent className={styles.container}>
    <div className={styles.title}>
      <h1>{title}</h1>
      {subtitle && <h2>{subtitle}</h2>}
    </div>

    <div className={styles.content}>
      <div className={styles.description}>
        {description}
      </div>

      {year && (
        <div className={styles.extra}>
          {`Year: ${year}`}
        </div>
      )}

      {material && (
        <div className={styles.extra}>
          {`Material: ${material}`}
        </div>
      )}
    </div>

    {images && (
    <div className={styles.gallery}>
      <Gallery items={images} showPlayButton={false} />
    </div>
    )}
  </PageContent>
);
