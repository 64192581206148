/* eslint-disable max-len, react/no-array-index-key */
import classNames from 'classnames';
import { FC } from 'react';
import imgLondon from '../assets/illustrations/london.webp';
import { PageContent } from '../components/layout/PageContent';
import { PageMeta } from '../components/layout/PageMeta';
import { illustrations } from '../data/illustrations';
import styles from './Illustrations.module.scss';

export const Illustrations: FC = () => (
  <PageContent altBackground kind="contained">
    <PageMeta title="Illustrations" />

    <div className={styles.container}>
      <p>
        My illustrations live in a world which is my free space where I can create everything and anything, playing with a dreamworld and people&apos;s perception in contrast with reality.
      </p>

      <p>
        I am self-taught in illustrating and learning techniques by creating and observing. As a young designer I can use new tools and technology, so I work with fine liners and photoshop so that I can build up my images in layers just like how images are digitally built these days.
      </p>
    </div>

    <div className={classNames(styles.image, styles.image_responsive)}>
      <img src={imgLondon} alt="" />
      <div className={styles.image_title}>London foxes</div>
    </div>

    <ul className={styles.grid}>
      {illustrations.map((column, i) => (
        <li key={i} className={styles.column}>
          {column.map((illustration, j) => (
            <div key={j} className={styles.image}>
              <img key={j} src={illustration.image} alt="" />
              <div className={styles.image_title}>{illustration.name}</div>
            </div>
          ))}
        </li>
      ))}
    </ul>
  </PageContent>
);
