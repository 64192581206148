import {
  FC, useState, MouseEvent, useEffect,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import styles from './MainMenu.module.scss';
import imgMenu from '../../assets/menu.png';
import imgMenuClose from '../../assets/menu-close.png';
import imgCatfish from '../../assets/catfish.webp';
import { workItems } from '../../data/work';

export const MainMenu: FC = () => {
  const location = useLocation();
  const [opened, setOpened] = useState(false);
  const toggleOpened = () => setOpened(!opened);

  useEffect(() => { setOpened(false); }, [location.pathname]);

  const stopBubble = (event: MouseEvent) => {
    if ((event.target as HTMLElement).tagName === 'A') {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div>
      <button type="button" className={styles.button} onClick={toggleOpened}>
        <img src={imgMenu} alt="Main menu" />
        <div>menu</div>
      </button>

      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={classnames(styles.backdrop, { [styles.backdrop_open]: opened })}
        onClick={toggleOpened}
      >

        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div onClick={stopBubble} className={styles.container}>
          <div className={styles.closeButton}>
            <button type="button" onClick={toggleOpened}>
              <img src={imgMenuClose} alt="Close menu" />
            </button>
          </div>

          <ul className={styles.menu}>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <span className={styles.item}>Work</span>

              <ul>
                {workItems.map((item) => (
                  <li key={item.slug}>
                    <Link className={styles.item} to={`/${item.slug}`}>{item.name}</Link>
                  </li>
                ))}
              </ul>
            </li>

            <li><Link className={styles.item} to="/postcards">Postcards</Link></li>
            <li><Link className={styles.item} to="/about">About</Link></li>
            <li><Link className={styles.item} to="/publications">Publications</Link></li>
            <li><Link className={styles.item} to="/contact">Contact</Link></li>
            <li><Link className={styles.item} to="/illustrations">Illustrations</Link></li>
            <li><Link className={styles.item} to="/press">Press</Link></li>
          </ul>

          <div className={styles.catfish_container}>
            <img alt="Menu" className={styles.catfish} src={imgCatfish} />
          </div>
        </div>
      </div>
    </div>
  );
};
