import { FC } from 'react';
import { PageContent } from '../components/layout/PageContent';
import { PageMeta } from '../components/layout/PageMeta';
import { publications } from '../data/publications';
import styles from './Publications.module.scss';

export const Publications: FC = () => (
  <PageContent altBackground className={styles.container} kind="contained">
    <PageMeta title="Publications" />

    {publications.map((publication) => (
      <div key={publication.image} className={styles.publication}>
        <img src={publication.image} alt={publication.name} />
        <div>{publication.name}</div>
      </div>
    ))}
  </PageContent>
);
