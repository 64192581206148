import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Home } from './views/Home';
import { NotFound } from './views/NotFound';
import { Illustrations } from './views/Illustrations';
import { WorkItem } from './views/WorkItem';
import { Publications } from './views/Publications';
import { Press } from './views/Press';
import { About } from './views/About';
import { Header } from './components/layout/Header';
import styles from './App.module.scss';
import { ScrollToTop } from './components/ScrollToTop';
import { Contact } from './views/Contact';
import { PostCards } from './views/PostCards';

export const App :FC = () => {
  const location = useLocation();

  return (
    <>
      <Helmet titleTemplate="%s | Eva Oyevaar" defaultTitle="Eva Oyevaar" />

      <ScrollToTop />

      <Header />

      <div className={styles.transition_container}>
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            classNames={{
              enter: styles.enter,
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
              exit: styles.exit,
              exitActive: styles.exitActive,
            }}
            timeout={500}
          >
            <div className={styles.route_container}>
              <Switch location={location}>
                <Route exact path="/" component={Home} />
                <Route exact path="/illustrations" component={Illustrations} />
                <Route exact path="/publications" component={Publications} />
                <Route exact path="/press" component={Press} />
                <Route exact path="/about" component={About} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/postcards" component={PostCards} />
                <Route exact path="/:slug" component={WorkItem} />
                <Route component={NotFound} />
              </Switch>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </>
  );
};
