/* eslint-disable max-len */
import { ComponentType, ReactNode } from 'react';
import { CityLife } from '../components/page/CityLife';
import imgAlternativeStork from '../assets/city_life/alternative_stork.webp';
import { ExternalLink } from '../components/ExternalLink';

export interface WorkItemImage {
  original: string;
  thumbnail: string;
}

export interface WorkItem {
  name: string;
  slug: string;
  subtitle?: string;
  description: ReactNode;
  year: number;
  material: string;
  home: {
    image: string;
    className?: string;
  },
  images?: WorkItemImage[];
  pageComponent?: ComponentType<{item: WorkItem}>;
}

export const workItems: WorkItem[] = [
  {
    name: 'Coming soon',
    slug: 'coming_soon',
    year: 2022,
    material: '',
    description: (
      <div>
        <p>More info coming soon</p>
      </div>
    ),
    home: {
      image: '/static/img/work/coming_soon/1.m.webp',
      className: 'orange',
    },
    images: getImages('coming_soon', 6),
  },
  {
    name: 'City Life',
    slug: 'citylife',
    year: 2019,
    material: 'Biotop paper 300 gram. / Felt stuffed animals.',
    description: (
      <div>
        <p>We decided to build cities, claiming lands as our own and build ourselves a home, but we never really thought of the effect this would have on the other inhabitants of this planet.</p>
        <p>Now, we are filled with negative emotions towards animals we have always lived with and whom have no real ill attend towards us. Besides, we have built the ideal living situations for them. The tall buildings are perfect shelters and homes, there is an unlimited food source in every street and there are lots of other friends and possible mates. A lot of species strive in cities and have larger populations inside the urbanization then they do in the wild. They have adapted to our lifestyle better then we have to theirs and that just simply feels unfair. What City Life is all about is to tell the story of the animals which are so disliked by us. Us being the humans living in the city and ignoring the beauty and good aspects that the animals do have.</p>
        <p>What I’m explaining through City Life is that we can use our time better to focus on the good and impressive qualities than to live in negativity and irritations.</p>
        <p className="alternative-stork">
          <img src={imgAlternativeStork} alt="" />
        </p>
        <p>City Life is a collection of books creating a positive perspective of Pigeons, foxes, and mouses. The series is open for growth into the worlds of other unappreciated animals trying to survive and strive in the urban wildlife.</p>
        <p>The project is currently still looking for investors and a publisher.</p>
        <p>For more information and interest in helping me bring this project to the people, please contact me!</p>
      </div>
    ),
    home: {
      image: '/static/img/work/citylife/14.m.webp',
      className: 'orange inverse',
    },
    images: getImages('citylife', 17),
    pageComponent: CityLife,
  },
  {
    name: 'Aperitif',
    slug: 'aperitif',
    year: 2019,
    material: 'Bullseye Glass',
    description: (
      <div>
        <p>By using the transparent quality of the glassware you can playfully interact with the series of objects giving an extra dimension. Aperitif is a series of colored glassware that can be stacked in endless combinations to create new hues.</p>
        <p>Normally we keep our Plates and bowls hidden behind cupboard doors, but why not allow it to transcend the functional as aesthetic objects worth putting on show in the interior? This collection is made to be seen, inviting attention and interaction. It invites you to stack, layer and combine to see different colors emerge.</p>
        <p>Aperitif is an example of a new technique to bring depth trough layering transparent surfaces and can be extended in many other applications.</p>
        <p>Aperitif is still in the production phase, if you are interested or want to know more you can email me and I&apos;ll inform you about it&apos;s status.</p>
      </div>
    ),
    home: {
      image: '/static/img/work/aperitif/2.m.webp',
      className: 'blue rotated',
    },
    images: getImages('aperitif', 10),
  },
  {
    name: 'Touch',
    slug: 'touch',
    subtitle: 'A virgin story',
    year: 2017,
    material: 'Ceramics Earthenware, High-temperature pigments.',
    description: <p>more information coming soon</p>,
    home: {
      image: '/static/img/work/touch/1.m.webp',
      className: 'blue inverse',
    },
    images: getImages('touch', 6),
  },
  {
    name: 'Roots',
    slug: 'adisplayfornature',
    subtitle: 'A display for nature',
    year: 2018,
    material: 'Textile front sheet, plexiglass back sheet, plastic display, metal frame and And the lovely plants.',
    description: <p>more information coming soon</p>,
    home: {
      image: '/static/img/work/roots/1.m.webp',
      className: 'orange inverse',
    },
    images: getImages('roots', 9),
  },
  {
    name: 'Firefly',
    slug: 'firefly',
    subtitle: 'Installation prototype',
    year: 2019,
    material: 'Installation for mistyfields festival in colaboration with Manon Jeuken',
    description: (
      <div>
        <p>There where the people are dancing, the lights dance with you.</p>
        <p>It all started with the fascination for the weird, innocent, dancing and luminous fireflies. Eva and Manon know each other from design Academy Eindhoven where their friendship started that now resulted in their first collaboration on this project.</p>
        <p>This project is in the prototype phase, but we are looking forward to continuing again with the work to create the perfect firefly light installation.</p>
      </div>
    ),
    home: {
      image: '/static/img/work/firefly/1.m.webp',
      className: 'blue',
    },
    images: getImages('firefly', 5),
  },
  {
    name: 'Muizen in de buizen',
    slug: 'muizen-in-de-buizen',
    subtitle: 'Toen en hier Eindhoven',
    year: 2019,
    material: 'Illustrations suporting the wonderful story of Hessel Rippe',
    description: (
      <div>
        <p>
          Eindhoven, 1952
          <br />
          Het is al vroeg druk bij de Philipsfabriek in het centrum. De vader van Niels en Sara kan er nauwelijks door met zijn bus vol schilderspullen. Hij is een van de schilders die de grote grijze fabriek helemaal wit gaat verven. Eigenlijk moeten ze bij hun vader blijven, maar Niels en Sara glippen stiekem de fabriek binnen. Ze kijken hun ogen uit. Dan hoort Sara een vreemd geluid in de buizen aan het plafond. Het leidt hen naar mevrouw De Wit en meneer De Bruin. En dat is maar goed ook, want die twee kunnen wel wat hulp gebruiken…
        </p>

        <p>
          <ExternalLink to="http://www.toen-hier.nl/">www.toen-hier.nl</ExternalLink>
          <br />
          <ExternalLink to="https://studio040.nl/nieuws/artikel/geschiedenisboeken-over-stad-dochters-als-eindredactie">Article studio 040</ExternalLink>
        </p>
      </div>
    ),
    home: {
      image: '/static/img/work/muizenindebuizen/3.m.webp',
      className: 'orange inverse',
    },
    images: getImages('muizenindebuizen', 5),
  },
  {
    name: 'Ceramics',
    slug: 'ceramics',
    year: 2019,
    material: 'Ceramics',
    description: (
      <div>
        <p>More info coming soon</p>
      </div>
    ),
    home: {
      image: '/static/img/work/ceramics/1.m.webp',
      className: 'blue rotated',
    },
    images: getImages('ceramics', 7),
  },
  {
    name: 'Olivia',
    slug: 'olivia',
    year: 2015,
    material: 'Aluminium and plants',
    description: <p>more info coming soon</p>,
    home: {
      image: '/static/img/work/olivia/2.m.webp',
      className: 'blue rotated',
    },
    images: getImages('olivia', 6),
  },
  {
    name: 'OOwl',
    slug: 'oowl',
    year: 2017,
    material: 'plastic, metal, wood.',
    description: <p>more info coming soon</p>,
    home: {
      image: '/static/img/work/oowl/4.m.webp',
      className: 'orange inverse',
    },
    images: getImages('oowl', 5),
  },
  {
    name: 'Fast concepts',
    slug: 'fast_concepts',
    year: 2020,
    material: '',
    description: <p>more info coming soon</p>,
    home: {
      image: '/static/img/work/fast_concepts/1.m.webp',
      className: 'blue inverse',
    },
    images: getImages('fast_concepts', 17),
  },
];

function getImages(name: string, amount: number): WorkItemImage[] {
  return new Array(amount).fill(0).map((_, i) => ({
    fullscreen: `/static/img/work/${name}/${i + 1}.l.webp`,
    original: `/static/img/work/${name}/${i + 1}.m.webp`,
    thumbnail: `/static/img/work/${name}/${i + 1}.m.webp`,
  }));
}
