import { FC, FormEvent } from 'react';
import { PageContent } from '../components/layout/PageContent';
import { PageMeta } from '../components/layout/PageMeta';
import styles from './Contact.module.scss';

export const Contact: FC = () => {
  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const values = {
      ...Object.fromEntries(new FormData(event.target as any)),
    } as any;

    window.location.href = `mailto:evaoyevaar@live.nl?${[
      `subject=${encodeURIComponent(values.subject)}`,
      `body=${encodeURIComponent(values.message)}`,
    ].join('&')}`;
  };

  return (
    <PageContent className={styles.container} kind="contained">
      <PageMeta title="Contact" />

      <section className={styles.section}>
        <h1>Contact</h1>

        <form onSubmit={onSubmit}>
          <input name="subject" placeholder="Subject" required />
          <textarea name="message" placeholder="Message" required />

          <div className={styles.last_row}>
            <button type="submit">Submit</button>
          </div>
        </form>
      </section>

      <section className={styles.section}>
        <h1>Address</h1>

        <p>
          Eva Oyevaar
          <br />
          Studio
          <br />
          Von Flotowlaan 1
          <br />
          5652AD Eindhoven
        </p>

        <div className={styles.map}>
          <iframe
            title="Address"
            frameBorder={0}
            scrolling="no"
            marginHeight={0}
            marginWidth={0}
            src="https://www.openstreetmap.org/export/embed.html?bbox=5.455540716648103%2C51.427985845800634%2C5.459081232547761%2C51.429430734564264&amp;layer=mapnik&amp;marker=51.428708295893834%2C5.457310974597931"
          />
          <br />
          <small>
            <a href="https://www.openstreetmap.org/?mlat=51.42871&amp;mlon=5.45731#map=19/51.42871/5.45731">
              View
              Larger Map
            </a>
          </small>
        </div>
      </section>
    </PageContent>
  );
};
