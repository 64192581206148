import { FC } from 'react';
import { Link } from 'react-router-dom';
import imgLogo from '../../assets/logo.webp';
import styles from './Header.module.scss';
import { MainMenu } from './MainMenu';
import { ExternalLink } from '../ExternalLink';

export const Header: FC = () => (
  <header className={styles.container}>
    <Link to="/" className={styles.logo}>
      <img src={imgLogo} alt="Studio Eva Oyevaar" />
    </Link>

    <nav className={styles.menu_container}>
      <ul className={styles.menu}>
        <li>
          <ExternalLink to="https://www.instagram.com/studioevaoyevaar/">
            Instagram @studioevaoyevaar
          </ExternalLink>
        </li>
        <li>
          <Link to="/postcards">Postcards for sale</Link>
        </li>
        <li>
          <Link to="/press">Press</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </nav>

    <MainMenu />
  </header>
);
