import imgAllalone from '../assets/illustrations/all-alone.webp';
import imgBedifferent from '../assets/illustrations/be-different.webp';
import imgCagedbird from '../assets/illustrations/caged-bird.webp';
import imgCatwithhat from '../assets/illustrations/cat-with-hat.webp';
import imgCatwithhat2 from '../assets/illustrations/cat-with-hat-2.webp';
import imgChicksontheline from '../assets/illustrations/chicks-on-the-line.webp';
import imgComfortcircle from '../assets/illustrations/comfort-circle.webp';
import imgCopypaste from '../assets/illustrations/copy-paste.webp';
import imgEatme_schrimp from '../assets/illustrations/eat-me_schrimp.webp';
import imgFamilyportrait from '../assets/illustrations/family-portrait.webp';
import imgFlamingovibe from '../assets/illustrations/flamingo-vibe.webp';
import imgLeanonme from '../assets/illustrations/lean-on-me.webp';
import imgLetitrain from '../assets/illustrations/let-it-rain.webp';
import imgLonglegs from '../assets/illustrations/long-legs.webp';
import imgMeandmyfriends from '../assets/illustrations/me-and-my-friends.webp';
import imgMissingyou from '../assets/illustrations/missing-you.webp';
import imgMymothertoldmeicouldbeanything from '../assets/illustrations/my-mother-told-me-i-could-be-anything.webp';
import imgOntheline from '../assets/illustrations/on-the-line.webp';
import imgPortraitthefatherfigure from '../assets/illustrations/portrait-the-father-figure.webp';
import imgSeagull from '../assets/illustrations/seagull.webp';
import imgSeagullsontheline from '../assets/illustrations/seagulls-on-the-line.webp';
import imgStork from '../assets/illustrations/stork.webp';
import imgStorktotheleft from '../assets/illustrations/stork-to-the-left.webp';
import imgStorktotheright from '../assets/illustrations/stork-to-the-right.webp';
import imgTheneartouch from '../assets/illustrations/the-near-touch.webp';
import imgYouarewhatyoueat_pelican from '../assets/illustrations/you-are-what-you-eat_pelican.webp';
import imgYouarewhatyoueat_thecatfish from '../assets/illustrations/you-are-what-you-eat_the-catfish.webp';
import imgYourarewhatyoueat_eel from '../assets/illustrations/your-are-what-you-eat_eel.webp';
import imgBeagleBday from '../assets/illustrations/beagle_bday.webp';
import imgGeboorteKaartje1 from '../assets/illustrations/geboorte_kaartje_1.webp';
import imgGeboorteKaartje2 from '../assets/illustrations/geboorte_kaartje_2.webp';
import imgHelpYou from '../assets/illustrations/help_you.webp';
import imgItsMyBday from '../assets/illustrations/it_s my bday.webp';
import imgKipAlleen from '../assets/illustrations/kip_alleen.webp';
import imgKroelkipjes from '../assets/illustrations/kroelkipjes.webp';
import imgMissPiggy from '../assets/illustrations/miss_piggy_has_a_fish_ass.webp';
import imgSheep from '../assets/illustrations/sheep.webp';

interface Illustration {
  name: string;
  image: string;
}

export const illustrations: Illustration[][] = [
  [
    { name: 'Kroelkipjes', image: imgKroelkipjes },
    { name: 'Help you', image: imgHelpYou },
    { name: 'Beagle bday', image: imgBeagleBday },
    { name: 'Cat with hat', image: imgCatwithhat2 },
    { name: 'Lean on me', image: imgLeanonme },
    { name: 'Caged bird', image: imgCagedbird },
    { name: 'Chicks on the line', image: imgChicksontheline },
    { name: 'Family portrait', image: imgFamilyportrait },
    { name: 'Let it rain', image: imgLetitrain },
    { name: 'On the line', image: imgOntheline },
    { name: 'Copy paste', image: imgCopypaste },
    { name: 'Long legs', image: imgLonglegs },
  ],

  [
    { name: 'Miss piggy has a fish ass', image: imgMissPiggy },
    { name: 'It\'s my b-day', image: imgItsMyBday },
    { name: 'Geboorte kaartje', image: imgGeboorteKaartje1 },
    { name: 'Cat with hat', image: imgCatwithhat },
    { name: 'Seagulls on the line', image: imgSeagullsontheline },
    { name: 'Stork to the left', image: imgStorktotheleft },
    { name: 'Be different', image: imgBedifferent },
    { name: 'Eat me _Schrimp', image: imgEatme_schrimp },
    { name: 'Portrait the father figure', image: imgPortraitthefatherfigure },
    { name: 'Me and my friends', image: imgMeandmyfriends },
    { name: 'You are what you eat _pelican', image: imgYouarewhatyoueat_pelican },
    { name: 'The near touch', image: imgTheneartouch },
    { name: 'Missing you', image: imgMissingyou },
  ],

  [
    { name: 'Sheep', image: imgSheep },
    { name: 'Kip alleen', image: imgKipAlleen },
    { name: 'Geboorte kaartje', image: imgGeboorteKaartje2 },
    { name: 'Stork', image: imgStork },
    { name: 'Stork to the right', image: imgStorktotheright },
    { name: 'You are what you eat _the catfish', image: imgYouarewhatyoueat_thecatfish },
    { name: 'Seagull', image: imgSeagull },
    { name: 'Comfort circle', image: imgComfortcircle },
    { name: 'All alone', image: imgAllalone },
    { name: 'My mother told me i could be anything', image: imgMymothertoldmeicouldbeanything },
    { name: 'Your are what you eat _eel', image: imgYourarewhatyoueat_eel },
    { name: 'Flamingo vibe', image: imgFlamingovibe },
  ],
];
