/* eslint-disable max-len */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { PageContent } from '../components/layout/PageContent';
import imgAbout1 from '../assets/about/1.webp';
import imgAbout2 from '../assets/about/2.webp';
import styles from './About.module.scss';
import { ExternalLink } from '../components/ExternalLink';
import { PageMeta } from '../components/layout/PageMeta';

export const About: FC = () => (
  <PageContent>
    <PageMeta title="About" />

    <div className={styles.hero}>
      <img src={imgAbout2} alt="" />

      <div>
        <h1 className={styles.heading}>About Studio Eva Oyevaar</h1>
        <h2 className={styles.subheading}>
          {/* eslint-disable-next-line max-len */}
          Eva Oyevaar is a Creative Illustrator applying her graphic skills in 2D and 3D Alumni of Design Academy Eindhoven
          <br />
          <span className={styles.subheading_end}>
            -2014/2019
          </span>
        </h2>

      </div>

      <img src={imgAbout1} alt="" />
    </div>

    <dl className={styles.category_list}>
      <dt className={styles.category_list_name}>
        About Me
      </dt>

      <dd className={styles.category_list_description}>
        <div className={styles.category_list_aboutme_content}>
          <h3>
            <Link to="/contact">Eva Oyevaar / evaoyevaar@live.nl</Link>
          </h3>

          <p>I&apos;m a product designer and Illustrator focussed on making objects and illustrations with a twist.</p>
          <p>In my 3D designs, I tend to focus on playfulness with color and composition, and in my 2D designs, I try to characterize animals and humans from my perspective. It&apos;s all about grasping the moment from the angle that I see it from and convincing the viewer of that perception.</p>
        </div>

        <dl className={styles.item_list}>
          <dt className={styles.item_list_name}>Instagram</dt>
          <dd className={styles.item_list_description}>
            <ExternalLink to="https://www.instagram.com/studioevaoyevaar/">@studioevaoyevaar</ExternalLink>
          </dd>

          <dt className={styles.item_list_name}>Email</dt>
          <dd className={styles.item_list_description}>
            <Link to="/contact">evaoyevaar@live.nl</Link>
          </dd>

          <dt className={styles.item_list_name}>Studio Address</dt>
          <dd className={styles.item_list_description}>Von Flotowlaan 1 / 5652AD Eindhoven</dd>

          <dt className={styles.item_list_name}>Phone</dt>
          <dd className={styles.item_list_description}>+31 (0)6 50505245</dd>

          <dt className={styles.item_list_name}>LinkedIN</dt>
          <dd className={styles.item_list_description}>
            <ExternalLink to="https://www.linkedin.com/in/eva-oyevaar-7a6367172/">Eva Oyevaar</ExternalLink>
          </dd>
        </dl>
      </dd>

      <dt className={styles.category_list_name}>
        Experience
      </dt>

      <dd className={styles.category_list_description}>
        <dl className={styles.item_list}>
          <dt className={styles.item_list_name}>Bachelor Degree Fine Arts</dt>
          <dd className={styles.item_list_description}>Design Academy . Department Man and Activity . Eindhoven . The Netherlands</dd>

          <dt className={styles.item_list_name}>Internship</dt>
          <dd className={styles.item_list_description}>Jorine Oosterhoff . Product design . Arnhem . The Netherlands</dd>

          <dt className={styles.item_list_name}>Minor Ceramics</dt>
          <dd className={styles.item_list_description}>Design Academy . Phoenix Cultuur . Vegel . The Netherlands</dd>
        </dl>
      </dd>

      <dt className={styles.category_list_name}>
        Exhibitions
      </dt>

      <dd className={styles.category_list_description}>
        <dl className={styles.item_list}>
          <dt className={styles.item_list_name}>Object Rotterdam</dt>
          <dd className={styles.item_list_description}>As part of Art Rotterdam 6-7-8-9 February 2020 HAKU building Rotterdam . Project Aperitif</dd>

          <dt className={styles.item_list_name}>Exhibition graduation show</dt>
          <dd className={styles.item_list_description}>
            DDW 2019 . Project Aperitif Campina Fabriek . Eindhoven . Design Academy
            {' '}
            <ExternalLink to="https://ddw.nl/en/programme/2003/graduation-show-2019">Link here</ExternalLink>
          </dd>

          <dt className={styles.item_list_name}>Exhibition Kazerne</dt>
          <dd className={styles.item_list_description}>Project Aperitif . Curated by Ilse Crawford en Oscar Peña</dd>

          <dt className={styles.item_list_name}>Exhibition Kunst en natuur route</dt>
          <dd className={styles.item_list_description}>Ilustrations and Project City Life Wemeldinge . route</dd>

          <dt className={styles.item_list_name}>Open house</dt>
          <dd className={styles.item_list_description}>
            Collaboration . Yvonne Oyevaar Goes .
            {' '}
            <ExternalLink to="https://yvonneoyevaar.nl/">Atelier Yvonne Oyevaar</ExternalLink>
          </dd>

          <dt className={styles.item_list_name}>Exhibition House Veere</dt>
          <dd className={styles.item_list_description}>Ilustrations and Ceramics Veere . Design house</dd>
        </dl>
      </dd>

      <dt className={styles.category_list_name}>
        Collaborations and projects
      </dt>

      <dd className={styles.category_list_description}>
        <dl className={styles.item_list}>
          <dt className={styles.item_list_name}>Illustrations for book</dt>
          <dd className={styles.item_list_description}>
            Toen & hier Eindhoven book series Book illustrations for &apos;Muizen in de Buizen&apos;
            <br />
            <ExternalLink to="http://www.toen-hier.nl/de-boeken/">Link to the website publisher</ExternalLink>
          </dd>

          <dt className={styles.item_list_name}>Installation festival</dt>
          <dd className={styles.item_list_description}>
            Hanging Firefly installation for the main tent above the dance floor
            <br />
            <Link to="/firefly">Link to project</Link>
          </dd>

          <dt className={styles.item_list_name}>Portrait illustration</dt>
          <dd className={styles.item_list_description}>
            Portrait of the daughter The episode aired on March 18th, 2019
            <br />
            <ExternalLink to="https://www.vtwonen.nl/tv/weer-verliefd-op-je-huis/seizoen-10-aflevering-4/zondag-17-maart-kijken-en-shoppen/">Link to the article of Interieur renovation</ExternalLink>
          </dd>
        </dl>
      </dd>

      <dt className={styles.category_list_name}>
        Shops
      </dt>

      <dd className={styles.category_list_description}>
        <dl className={styles.item_list}>
          <dt className={styles.item_list_name}>De makers van Bergen op Zoom</dt>
          <dd className={styles.item_list_description}>
            Illustrations printed on postcards and A3
            <br />
            <ExternalLink to="https://www.demakersvanboz.nl/">https://www.demakersvanboz.nl/</ExternalLink>
            <br />
            Lombardenstraat 14
            <br />
            Bergen op Zoom
          </dd>
        </dl>
      </dd>
    </dl>
  </PageContent>
);
