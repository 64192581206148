/* eslint-disable max-len */
import { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { PageMeta } from '../components/layout/PageMeta';
import { WorkDetail } from '../components/page/WorkDetail';
import { workItems } from '../data/work';
import { NotFound } from './NotFound';

export const WorkItem: FC<RouteComponentProps<{slug: string}>> = ({ match }) => {
  const item = workItems.find((i) => i.slug === match.params.slug);

  if (!item) {
    return <NotFound />;
  }

  return (
    <>
      <PageMeta title={item.name} />

      {item.pageComponent ? <item.pageComponent item={item} /> : (
        <WorkDetail
          title={item.name}
          subtitle={item.subtitle}
          year={item.year}
          material={item.material}
          description={item.description}
          images={item.images}
        />
      )}
    </>
  );
};
