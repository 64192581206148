import React, { FC } from 'react';
import classnames from 'classnames';
import { Footer } from './Footer';
import styles from './PageContent.module.scss';

export interface LayoutProps {
  altBackground?: boolean;
  className?: string;
  kind?: 'full' | 'contained';
}

export const PageContent: FC<LayoutProps> = ({
  altBackground,
  children,
  className,
  kind = 'full',
}) => (
  <div className={classnames(styles.body, { [styles.alt_background]: altBackground })}>
    <main className={classnames(styles[`kind_${kind}`], className)}>
      {children}
    </main>

    <Footer />
  </div>
);
