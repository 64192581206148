import classNames from 'classnames';
import { WorkItem } from '../../data/work';
import { PageContent } from '../layout/PageContent';
import styles from './WorkDetail.module.scss';
import stylesCityLife from './CityLife.module.scss';
import { Gallery } from '../gallery/Gallery';
import imgTitle from '../../assets/city_life/title.webp';

interface CityLifeProps {
  item: WorkItem
}

export function CityLife({ item }: CityLifeProps) {
  return (
    <PageContent className={classNames(styles.container, stylesCityLife.container)}>
      <h1 className={classNames(styles.title, stylesCityLife.title)}>
        <img src={imgTitle} alt="City life" />
      </h1>

      <div className={classNames(styles.content, stylesCityLife.content)}>
        <div className={styles.description}>
          {item.description}
        </div>

        {item.year && (
          <div className={styles.extra}>
            {`Year: ${item.year}`}
          </div>
        )}

        {item.material && (
          <div className={styles.extra}>
            {`Material: ${item.material}`}
          </div>
        )}
      </div>

      {item.images && (
        <div className={classNames(styles.gallery, stylesCityLife.gallery)}>
          <Gallery items={item.images} showPlayButton={false} />
        </div>
      )}
    </PageContent>
  );
}
