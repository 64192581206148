import { FC } from 'react';
import { PageContent } from '../components/layout/PageContent';
import { PageMeta } from '../components/layout/PageMeta';
import { pressDownloads } from '../data/press';
import styles from './Press.module.scss';

export const Press: FC = () => (
  <PageContent kind="contained">
    <PageMeta title="Press" />

    <div className={styles.description}>
      <p>
        All Images are free of rights and can be used if:
        <br />
        The designer name, Project Title and Photographer are mentioned correctly with the images.
      </p>
      <p>
        {/* eslint-disable-next-line max-len */}
        Please email me to inform me about the publication beforehand and ask for the approval of written texts.
      </p>
    </div>

    <ul className={styles.list}>
      {pressDownloads.map((download) => (
        <li className={styles.list_item}>
          <a href={download.url} download={download.name} target="_blank" rel="noopener noreferrer">
            {download.name}
          </a>
        </li>
      ))}
    </ul>
  </PageContent>
);
