import { FC } from 'react';
import { PageContent } from '../components/layout/PageContent';
import { PageMeta } from '../components/layout/PageMeta';

export const NotFound: FC = () => (
  <PageContent>
    <PageMeta title="Page not found" />

    Not Found
  </PageContent>
);
