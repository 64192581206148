import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { PageContent } from '../components/layout/PageContent';
import { PageMeta } from '../components/layout/PageMeta';
import { workItems } from '../data/work';
import styles from './Home.module.scss';

export const Home: FC = () => (
  <PageContent altBackground>
    <PageMeta title="Work" />

    <ul className={styles.work_list}>
      {workItems.map((item) => (
        <HomeItem
          key={item.name}
          url={`/${item.slug}`}
          title={item.name}
          subtitle={item.subtitle}
          image={item.home.image}
          className={item.home.className}
        />
      ))}
    </ul>
  </PageContent>
);

interface HomeItemProps {
  title: string;
  subtitle?: string;
  image: string;
  url: string;
  className?: string;
}

const HomeItem: FC<HomeItemProps> = ({
  title, subtitle, image, url, className,
}) => (
  <li className={classNames(styles.item, className)}>
    <Link to={url}>
      <div className={styles.heading}>
        <div className={styles.henk}>
          <h2>{title}</h2>
          {subtitle && <h3>{subtitle}</h3>}
        </div>
      </div>

      <div className={styles.item_image}>
        <img src={image} alt="" />
      </div>
    </Link>
  </li>
);
